<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 7L13.4641 11.5H6.5359L10 7Z" fill="currentColor" />
    </svg>
  </i>
</template>

<style scoped lang="scss">
i {
  width: 20px;
  height: 20px;
  display: inline-flex;
}
</style>
